<template>
    <div class="bgcontent">
        <Header style="background: none">
            <template slot="title">立刻购买</template>
        </Header>
        <div class="body">
            <div class="notice" v-html="desc">
            </div>
            <div class="box margin24">
                <div class="title">{{ orderInfo.entrust_no }}</div>
                <div class="middle">
                    <div class="price">
                        <div class="num">
                            {{ parseFloat(orderInfo.price).toFixed(3) || 0 }}
                        </div>
                        <div class="txt">单价(USD)</div>
                    </div>
                    <div class="price offset">
                        <div class="txt">
                            求购量：
                            {{ parseFloat(orderInfo.deals_remain_num).toFixed(4) || 0 }} {{ orderInfo.stock_code }}
                        </div>
                        <!--<div class="txt">-->
                            <!--限额CNY：{{ parseFloat(orderInfo.min_price).toFixed(2) || 0 }}-{{-->
                            <!--parseFloat(orderInfo.max_price).toFixed(2) || 0-->
                            <!--}}-->
                        <!--</div>-->
                        <div class="txt">
                            总价值：{{ (parseFloat(orderInfo.deals_remain_num)*parseFloat(orderInfo.price)).toFixed(3) }}
                             USD
                        </div>
                    </div>
                </div>
                <div class="end">
                    <div class="way">
                        <div class="method">
                            支付方式：
                            <div
                                    class="paylogo"
                                    v-for="pay in orderInfo.pay_type.split(',')"
                                    :key="pay"
                            >
                                <img
                                        v-if="pay === '1'"
                                        class="payicon"
                                        :src="require('@/assets/images/mine/usdt.png')"
                                />
                                <img
                                        v-if="pay === '2'"
                                        class="payicon"
                                        :src="require('@/assets/images/mine/zhifubao.png')"
                                />
                                <img
                                        v-if="pay === '3'"
                                        class="payicon"
                                        :src="require('@/assets/images/mine/card.png')"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="payway margin24">
                <div class="top">
                    <div class="txt">选择支付方式</div>
                    <div class="txt1">支付方式 ></div>
                </div>

                <div class="paytype margin16">
                    <div
                            :class="payArr.indexOf('2') == -1 ? 'typebox' : 'selectBox'"
                            @click="selectPay('2')"
                    >
                        <img
                                class="payimg"
                                :src="require('@/assets/images/mine/zhifubao.png')"
                        />
                        <div class="tip">支付宝</div>
                    </div>
                    <div    v-if="coin!='USDT'"
                            :class="payArr.indexOf('1') == -1 ? 'typebox' : 'selectBox'"
                            @click="selectPay('1')"
                    >
                        <img
                                class="payimg"
                                :src="require('@/assets/images/mine/usdt.png')"
                        />
                        <div class="tip">USDT</div>
                    </div>
                    <div></div>
                    <div
                            :class="payArr.indexOf('3') == -1 ? 'typebox' : 'selectBox'"
                            @click="selectPay('3')"
                    >
                        <img
                                class="payimg"
                                :src="require('@/assets/images/mine/card.png')"
                        />
                        <div class="tip">银行卡</div>
                    </div>
                </div>
                <div class="buynum margin16">
                    <van-field
                            class="inputClass"
                            v-model="buynumber"
                            name="buynumber"
                            placeholder="请输入购买数量"
                            readonly
                    ></van-field>
                    <!--@keyup="checkNum()"-->
                </div>
                <div class="btncell margin48">
                    <div class="btn" @click="clickSubmit">确认下单</div>
                </div>
                <div class="tip1 margin16">预期支付 (CNY)：{{ parseFloat(cnyAmount).toFixed(2) }} / (USD)：{{
                    parseFloat(usdtAmount).toFixed(2) }}
                </div>
            </div>
            <div class="step margin24">
                <div class="stepbox">
                    <div class="tipbox">第一步</div>
                    <div class="txt margin16">
                        1.添加支付方式<br/>
                        2.选择匹配订单<br/>
                        3.确认信息,下单成功
                    </div>
                </div>
                <div class="stepbox">
                    <div class="tipbox">第二步</div>
                    <div class="txt margin16">
                        1.查看订单记录<br/>
                        2.找到待付款订单<br/>
                        3.根据支付方式付款并上传凭证
                    </div>
                </div>
            </div>
        </div>
        <van-dialog v-model="showpwd" title="确认下单" @cancel="pwdShow" @confirm="clickbuyOrder" show-cancel-button>
            <van-field type="password" v-model="password" name="安全密码" label="安全密码" placeholder="请输入安全密码"></van-field>
        </van-dialog>
    </div>
</template>

<script>
    import {getOptionDesc, orderDetail, buyOrder, tradeStatistics} from "@/request/api";
    import Header from "../../components/header.vue";
    import {Toast} from "vant";

    export default {
        name: "Buynow",
        components: {
            Header,
        },
        data() {
            return {
                buynumber: "",
                desc: "",
                orderID: "",
                type: "",
                orderInfo: {
                    entrust_no: "",
                    price: "",
                    stock_code: "",
                    trans_num: "",
                    min_price: "",
                    max_price: "",
                    pay_type: "",
                    fee: "",
                },
                payArr: [],
                address: "",
                userInfo: {},
                showpwd: false,
                password: '',
                tradeInfo: {},
                coin: '',
                cnyAmount: 0,
                cnyPrice: 0,
                stockPrice: 0,
                usdtAmount: 0,
                cny_to_us: 0,
            };
        },
        activated() {
            this.orderID = this.$route.query.id;
            this.type = this.$route.query.type;
            this.address = sessionStorage.getItem("address");
            this.userInfo = sessionStorage.getItem("user")
                ? JSON.parse(sessionStorage.getItem("user"))
                : {};
            this.buynumber = "";
            this.payArr = [];
            this.getOptionDesc();
            this.getOrderDetail();

        },
        methods: {
            checkNum() {
                this.cnyPrice = parseFloat(this.stockPrice) * parseFloat(this.cny_to_us);
                if (this.buynumber <= 0) {
                    this.buynumber = 0;
                } else {
                    this.buynumber = parseFloat(this.buynumber);
                }
                this.cnyAmount = (parseFloat(this.buynumber) * parseFloat(this.cnyPrice)).toFixed(2);
                this.usdtAmount = (parseFloat(this.buynumber) * parseFloat(this.stockPrice)).toFixed(2);
            },
            gettradeStatistics() {
                const params = {
                    coin_symbol: this.coin,
                };
                tradeStatistics(params).then((res) => {
                    this.tradeInfo = res.data;
                    this.cny_to_us = parseFloat(res.data.cny_to_us);
                    this.checkNum();
                });
            },
            pwdShow() {
                this.showpwd = false;
            },
            clickbuyOrder() {
                let _this = this;
                if (!this.password) {
                    Toast('请输入密码');
                    return
                }
                const params = {
                    address: this.address,
                    pay_code: this.password,
                    buy_pay_type: this.payArr.join(","),
                    amount: this.buynumber,
                    id: this.orderID,
                };
                buyOrder(params).then((res) => {
                    if (res.success) {
                        this.buynumber = "";
                        this.payArr = [];
                        Toast('操作成功');
                        setTimeout(function () {
                            _this.$router.push({
                                path: "/task/order",
                                query: {
                                    coin: _this.coin,
                                },
                            });
                        }, 2500)
                    } else {
                        Toast(res.msg);
                    }
                });
            },
            getOrderDetail() {
                const params = {
                    id: this.orderID,
                    type: this.type,
                };
                orderDetail(params).then((res) => {
                    if (res.code == 200) {
                        this.orderInfo = res.data;
                        this.buynumber = res.data.deals_remain_num;
                        this.coin = this.orderInfo.stock_code;
                        this.stockPrice = parseFloat(res.data.price);
                        this.gettradeStatistics();
                    }
                });
            },
            getOptionDesc() {
                const params = {
                    name: "trade_desc",
                    address: this.$store.state.user.userInfo.address,
                };
                getOptionDesc(params).then((res) => {
                    this.desc = res.data.value_zh;
                });
            },
            clickSubmit() {
                let _this = this;
                if (!this.address) {
                    Toast('请使用币安智能链链接')
                    return
                }
                if (this.address && this.userInfo.has_paypwd != 1) {
                    Toast('请先完善资料,设置安全密码');
                    setTimeout(function () {
                        _this.$router.push({
                            path: '/home/mine'
                        })
                    })
                    return
                }
                if (parseFloat(this.buynumber) <= 0) {
                    Toast("请输入购买数量");
                    return;
                }
                if (this.payArr.length === 0) {
                    Toast("请选择支付方式");
                    return;
                }
                if (parseFloat(this.buynumber) > parseFloat(this.orderInfo.deals_remain_num)) {
                    Toast("购买数量不能大于订单数量");
                    return;
                }
                // if (parseFloat(this.cnyAmount) < parseFloat(this.orderInfo.min_price)
                //     || parseFloat(this.cnyAmount) > parseFloat(this.orderInfo.max_price)) {
                //     Toast("购买超出订单限额");
                //     return;
                // }
                // 判断是否在交易时间
                var nowTime = (new Date).getTime() / 1000;
                if(this.coin == 'USDT'){
                    if (nowTime > parseInt(this.tradeInfo.endUsdtTimestamp) || nowTime < parseInt(this.tradeInfo.startUsdtTimestamp)) {
                        Toast('今日交易时间段未开启或已结束');
                        return false;
                    }
                }else{
                    if (nowTime > parseInt(this.tradeInfo.endTimestamp) || nowTime < parseInt(this.tradeInfo.startTimestamp)) {
                        Toast('今日交易时间段未开启或已结束');
                        return false;
                    }
                }
                this.showpwd = true;
            },
            selectPay(value) {
                let index = this.payArr.indexOf(value);
                if (index === -1) {
                    this.payArr.push(value);
                } else {
                    this.payArr.splice(index, 1);
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .offset {
        margin-left: 90px;
    }

    .margin24 {
        margin-top: 24px;
    }

    .margin16 {
        margin-top: 16px;
    }

    .margin48 {
        margin-top: 48px;
    }

    .bgcontent {
        background: rgba(13, 25, 18, 1);
        min-height: 100vh;
        .body {
            padding: 24px 32px;
            .notice {
                opacity: 1;
                color: #b6d9c3;
                font-size: 24px;
                font-weight: 700;
                text-align: left;
                line-height: 44px;
                font-family: "PingFang SC";
            }
            .box {
                width: 686px;
                height: 276px;
                border-radius: 24px;
                opacity: 1;
                background: rgba(32, 40, 35, 1);
                padding: 24px;
                .title {
                    color: rgba(182, 217, 195, 1);
                    font-size: 26px;
                    font-weight: 400;
                    font-family: "PingFang SC";
                }
                .middle {
                    margin-top: 16px;
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    .price {
                        .num {
                            color: rgba(214, 255, 227, 1);
                            font-size: 44px;
                            font-weight: 500;
                            font-family: "DIN";
                            text-align: center;
                            line-height: 60px;
                        }
                        .txt {
                            color: rgba(139, 166, 149, 1);
                            font-size: 24px;
                            font-weight: 400;
                            font-family: "PingFang SC";
                            text-align: left;
                            line-height: 36px;
                        }
                    }
                }
                .end {
                    margin-top: 24px;
                    display: flex;
                    justify-content: space-between;
                    .way {
                        color: rgba(139, 166, 149, 1);
                        font-size: 28px;
                        font-weight: 400;
                        font-family: "PingFang SC";
                        text-align: left;
                        line-height: 40px;
                        display: flex;
                        align-items: center;
                        .method {
                            display: flex;
                            align-items: center;
                            .paylogo {
                                display: flex;
                                align-items: center;
                                .payicon {
                                    margin-right: 10px;
                                    width: 32px;
                                    height: 32px;
                                }
                            }
                        }
                    }
                    .buy {
                        color: rgba(39, 204, 127, 1);
                        font-size: 28px;
                        font-weight: 700;
                        font-family: "PingFang SC";
                        text-align: center;
                        line-height: 40px;
                    }
                }
            }
            .payway {
                width: 686px;
                height: 484px;
                border-radius: 24px;
                opacity: 1;
                background: rgba(16, 43, 29, 1);
                padding: 24px;
                .top {
                    display: flex;
                    justify-content: space-between;
                    .txt {
                        color: rgba(214, 255, 227, 1);
                        font-size: 32px;
                        font-weight: 400;
                        font-family: "PingFang SC";
                        text-align: left;
                    }
                    .txt1 {
                        color: rgba(39, 204, 127, 1);
                        font-size: 28px;
                        font-weight: 400;
                        font-family: "PingFang SC";
                    }
                }

                .paytype {
                    display: flex;
                    /*justify-content: space-between;*/
                    justify-content: space-around;
                    .typebox {
                        width: 188px;
                        height: 72px;
                        border-radius: 16px;
                        opacity: 1;
                        border: 2px solid rgba(139, 166, 149, 1);
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        .payimg {
                            width: 40px;
                            height: 40px;
                        }
                        .tip {
                            color: rgba(139, 166, 149, 1);
                            font-size: 32px;
                            font-weight: 700;
                            font-family: "PingFang SC";
                        }
                    }
                    .selectBox {
                        width: 188px;
                        height: 72px;
                        border-radius: 16px;
                        opacity: 1;
                        border: 2px solid rgba(39, 204, 127, 1);
                        background: rgba(39, 204, 127, 0.2);
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        .payimg {
                            width: 40px;
                            height: 40px;
                        }
                        .tip {
                            color: rgba(39, 204, 127, 1);
                            font-size: 32px;
                            font-weight: 700;
                            font-family: "PingFang SC";
                        }
                    }
                }
                .buynum {
                    .inputClass {
                        background: #29332d;
                        ::v-deep .van-field__control {
                            color: white;
                        }
                    }
                }
                .btncell {
                    display: flex;
                    justify-content: center;
                    .btn {
                        width: 320px;
                        height: 88px;
                        border-radius: 16px;
                        opacity: 1;
                        background: rgba(39, 204, 127, 1);
                        color: rgba(32, 40, 35, 1);
                        font-size: 36px;
                        font-weight: 700;
                        font-family: "PingFang SC";
                        text-align: center;
                        line-height: 88px;
                    }
                }
                .tip1 {
                    color: rgba(139, 166, 149, 1);
                    font-size: 28px;
                    font-weight: 700;
                    text-align: center;
                }
            }
            .step {
                display: flex;
                justify-content: space-between;
                .stepbox {
                    width: 328px;
                    height: 284px;
                    border-radius: 24px;
                    opacity: 1;
                    background: rgba(32, 40, 35, 1);
                    padding: 24px;
                    .tipbox {
                        width: 104px;
                        height: 44px;
                        border-radius: 8px;
                        opacity: 1;
                        border: 2px solid rgba(39, 199, 204, 1);
                        color: rgba(39, 199, 204, 1);
                        font-size: 24px;
                        font-weight: 400;
                        text-align: center;
                        line-height: 44px;
                    }
                    .txt {
                        color: rgba(139, 166, 149, 1);
                        font-size: 24px;
                        font-weight: 400;
                        line-height: 44px;
                    }
                }
            }
        }
    }
</style>